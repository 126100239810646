export default {
  Dashboard: "Dashboard",
  Users: "Users",
  Decisions: "Decisions",
  Response: "Response",
  "User List": "User List",
  Applications: "Applications",
  Verified: "Verified",
  Unverified: "Unverified",
  Dump: "Dump",
  "Create User": "Create User",
  "Question & Answers": "Question & Answers",
  "Create Set": "Create Set",
  "Create Decision": "Create Decision",
  "All Decisions": "All Decisions",
  "Decision Responses": "Decision Responses",
  Menu: "MENU",
  Admin: "Admin",
  Search: "Search",
  "My Account": "My Account",
  Logout: "Logout",
  "Verified Users": "Verified Users",
  "User Id": "User Id",
  Questions: "Questions",
  Calculations: "Calculations",
  Eligibilty: "Eligibilty",
  "View Answers": "View Answers",
  "Edit/Delete": "Edit/Delete",
  "Dump Users": "Dump Users",
  "Approved/Hold": "Approved/Hold",
  "Create User": "Create User",
  "Last Name": "Last Name",
  "First Name": "First Name",
  DOB: "DOB",
  Email: "Email",
  Password: "Password",
  "ID number": "ID Number",
  "Choose Option": "Choose Option",
  "Mobile Number": "Mobile Number",
  Submit: "Submit",
  Moderater: "Moderater",
  Admin: "Admin",
  User: "User",
  "Main Name": "Main Name",
  "Questions List": "Questions List",
  Heading: "Heading",
  Question: "Question",
  Type: "Type",
  Values: "Values",
  Action: "Action",
  "Add New Question": "Add New Question",
  None: "None",
  TextBox: "TextBox",
  "Boolean Value": "Boolean Value",
  CheckBoxes: "CheckBoxes",
  "Increase Decrease": "Increase Decrease",
  "Add to form": "Add to form",
  "All List Of Questions": "All List Of Questions",
  "Add Selected To New List": "Add Selected To New List",
  "All List Of Sets": "All List Of Sets",
  heading: "heading",
  "Set name": "Set name",
  "Create Decision": "Create Decision",
  "Select Set": "Select Set",
  "List Of Decisions": "List Of Decisions",
  "Search Decision from here": "Search Decision from here",
  "My Profile": "My Profile",
  Role: "Role",
  Edit: "Edit",
  "User Dashboard": "User Dashboard",
  "Loan Management": "Loan Management",
  "Create Type": "Create Type",
  "Loan Applications": "Loan Applications",
  Notifications: "Notifications",
  "Add Question": "Add Question",
  "For Success Response": "For Success Response",
  "For Error Response": "For Error Response",
  Description: "Description",
  Message: "Message",
  Overview: "Overview",
  Account: "Account",
  Customers: "Customers",
  "Customers Dashboard": "Customers Dashboard",
  "All Customers": "All Customers",
  "Verified Customers": "Verified Customers",
  "Dump Customers": "Dump Customers",
  Administrator: "Administrator",
  "Create Admin": "Create Admin",
  "Create Rights To User": "Create Rights To User",
  Installments: "Installments",
  "Term And Conditions": "Term And Conditions",
  Agreement: "Agreement",
  Simah: "Simah",
  calculations: "calculations",
  "Bare Minimum Expense": "Bare Minimum Expense",
  "Terms And Rates": "Terms And Rates",
  "Total Deposite Amount": "Total Deposite Amount",
  "Total Withdrawal Amount": "Total Withdrawal Amount",
  "Balance In Account": "Balance In Account",
  "Total Applications": "Total Applications",
  "My Applications": "My Applications",
  "In Process": "In Process",
  Change: "Change",
  "Action Center": "Action Center",
  "User Activity": "User Activity",
  "Applications Status": "Applications Status",
  "Loan Reason": "Loan Reason",
  "Loan Ammount": "Loan Ammount",
  Duration: "Duration",
  "Maturity Date": "Maturity Date",
  "All Applications": "All Applications",
  Approved: "Approved",
  Pending: "Pending",
  Rejected: "Rejected",
  All: "All",
  "Loan Applications": "Loan Applications",
  "View Details": "View Details",
  "On Hold": "On Hold",
  "Un Verified": "Un Verified",
  "Monthly Activity Of Users": "Monthly Activity Of Users",
  "Recent Activities": "Recent Activities",
  "Users Anti Fraud History": "Users Anti Fraud History",
  "Performance Score": "Performance Score",
  "Phone/Email": "Phone/Email",
  Name: "Name",
  "Guardian Name": "Guardian Name",
  "Id Number": "Id Number",
  Mobile: "Mobile",
  Status: "Status",
  Blocked: "Blocked",
  Active: "Active",
  "De Activate": "De Activate",
  Activate: "Activate",
  "All Users": "All Users",
  "Search With Id Number": "Search With Id Number",
  Eligible: "Eligible",
  Pass: "Pass",
  Fail: "Fail",
  "Not Eligible": "Not Eligible",
  Profile: "Profile",
  "Api Details": "Api Details",
  "User Info": "User Info",
  "Financial Reports": "Financial Reports",
  "All Admins and Moderators": "All Admins and Moderators",
  "Add New User": "Add New User",
  "Choose Role": "Choose Role",
  "User Name": "User Name",
  "Add User": "Add User",
  "Create Loan Type": "Create Loan Type",
  "Loan Reason": "Loan Reason",
  Tensures: "Tensures",
  Ratio: "Ratio",
  Months: "Months",
  "All Loan Types": "All Loan Types",
  Browse: "Browse",
  "Supported formates: Ico, PNG": "Supported formates: Ico, PNG",
  "Add More Tenures": "Add More Tenures",
  "Amount And Taxes As Per Month": "Amount And Taxes As Per Month",
  "Processing Fee": "Processing Fee",
  "Vat on Fee": "Vat on Fee",
  Fee: "Fee",
  "Installments Products": "Installments Products",
  "Add New Product": "Add New Product",
  Image: "Image",
  Title: "Title",
  Price: "Price",
  "Product Image": "Product Image",
  "Create Installment Product": "Create Installment Product",
  "Income Bracket": "Income Bracket",
  TO: "TO",
  "Product Level": "Product Level",
  "Customer DBR": "Customer DBR",
  "GDBR (Without MTG)": "GDBR (Without MTG)",
  "GDBR (Include MTG)": "GDBR (Include MTG)",
  "Income Bracket SAR": "Income Bracket SAR",
  "Customer DBR": "Customer DBR",
  "GDBR (Including MTG)": "GDBR (Including MTG)",
  "Net Income": "Net Income",
  "Delete DBR": "Delete DBR",
  "Are you sure to delete this ??": "Are you sure to delete this ?",
  "Add DBR": "Add DBR",
  "Add Bare Minimum Expense": "Add Bare Minimum Expense",
  "Bare Minimum expense Per Person": "Bare Minimum expense Per Person",
  Expenses: "Expenses",
  "Admin Fee In Percentage": "Admin Fee In Percentage",
  "Annual Rate": "Annual Rate",
  "Vat In Percentage": "Vat In Percentage",
  "Flat Rate Monthly": "Flat Rate Monthly",
  Term: "Term",
  "Term Rate": "Term Rate",
  "Add Terms And Rates": "Add Terms And Rates",
  "Total Notifications": "Total Notifications",
  Clicked: "Clicked",
  Delivered: "Delivered",
  "Not Click Yet": "Not Click Yet",
  "Add New Notification": "Add New Notification",
  Icon: "Icon",
  Subject: "Subject",
  Content: "Content",
  "Monthly installment": "Monthly installment",
  "Vat Fee": "Vat Fee",
  "Interest Ammount": "Interest Ammount",
  "Are you sure to delete ?": "Are you sure to delete ?",
  "Delete User": "Delete User",
  Cancel: "Cancel",
  Delete: "Delete",
  "All List Of Questions in this set": "All List Of Questions in this set",
  "View All Screens": "View All Screens",
  "Delete Set": "Delete Set",
  Add: "Add",
  Id: "Id",
  Options: "Options",
  Formula: "Formula",
  "Other Questions": "Other Questions",
  "Text Questions": "Text Questions",
  "Create Agreement": "Create Agreement",
  "Create Terms And Conditions": "Create Terms And Conditions",
  "Terms And Conditions": "Terms And Conditions",
  Conditions: "Conditions",
  Creditor: "Creditor",
  "Member Name": "Member Name",
  "SIMAH Members": "SIMAH Members",
  Existing: "Existing",
  "SIMAH Product List": "SIMAH Product List",
  "Serial Number": "Serial Number",
  Code: "Code",
  Arabic: "Arabic",
  "Product Group": "Product Group",
  "Product Category": "Product Category",
  Product: "Product",
  "Simah Description": "Simah Description",
  Issuer: "Issuer",
  Guidlines: "Guidlines",
  "Close modal": "Close modal",
  "Create Bare Minimum Expense": "Create Bare Minimum Expense",
  "Bare Minimum Title": "Bare Minimum Title",
  "Bare Minimum Value": "Bare Minimum Value",
  "Create Notification": "Create Notification",
  "Notification Icon": "Notification Icon",
  "Choose Navigation": "Choose Navigation",
  none: "none",
  "Choose User": "Choose User",
  "Formula Name": "Formula Name",
  "Add Answers": "Add Answers",
  "Add Answer to this Question": "Add Answer to this Question",
  Answers: "Answers",
  "Sign in to your account": "Sign in to your account",
  "Forgot password?": "Forgot password",
  "Remember me": "Remember me",
  "Don’t have an account yet?": "Don’t have an account yet?",
  "Sign up": "Sign up",
  "Sign in": "Sign in",
  "Enter Your Id Number": "Enter Your Id Number",
  Continue: "Continue",
  "We have sent a code to your ID Number":
    "We have sent a code to your ID Number",
  "Id Number Verification": "Id Number Verification",
  "Verify Account": "Verify Account",
  "Didn't recieve code?": "Didn't recieve code?",
  "Resend code in": "Resend code in",
  Resend: "Resend",
  "Change Password": "Change Password",
  "Re-Type Password": "Re-Type Password",
  "You Can Write Your": "You Can Write Your New Password Here",
  "Device Token:": "Device Token:",
  "Ip Address:": "Ip Address:",
  "Mac Address:": "Mac Address:",
  Sessions: "Sessions",
  "Device Info": "Device Info",
  "Nothing To Show!": "Nothing To Show!",
  "Total Value:": "Total Value:",
  Reference_Id: "Reference_Id:",
  Number: "Number:",
  "Due Type:": "Due Type:",
  "Currently Logged In": "Currently Logged In",
  "Logged In Time": "Logged In Time",
  "Logged Out Time": "Logged Out Time",
  "Expenses Frequency": "Expenses Frequency",
  "Bare Minimum Expenses": "Bare Minimum Expenses",
  "Customer Declared Expenses": "Customer Declared Expenses",
  "Final Monthly Expenses": "Final Monthly Expenses",
  "View Sanad": "View Sanad",
  validity_period_saudi: "Validity Period Saudi",
  validity_period_expat: "Validity Period Expat",
  min_age: "Min age",
  max_age: "Max age",
  min_income: "Min Income",
  min_finance_amount: "Min Finance Amount",
  max_finance_amount: "Max Finance Amount",
  min_service_length: "Min Service Length",
  overall_experience: "Overall Experience",
  min_loan_tenure: "Min Loan Tenure",
  max_loan_tenure: "Max Loan Tenure",
  simah_performance: "Simah Performance",
  write_off_min: "Write Off Min",
  price_by_tenure: "Price By Tenure",
  "Policy Name": "Policy Name",
  "Policy Value": "Policy Value",
  History: "History",
  "Check Policy History": "Check Policy History",
  "Update Policy": "Update Policy",
  Update: "Update",
  Reject: "Reject",
  Approve: "Approve",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  "Modified Date": "Modified Date",
  "Created Date": "Created Date",
  "New Value": "New Value",
  "Previous Value": "Previous Value",
  "Policy Name": "Policy Name",
  "All Policies": "All Policies",
  Policies: "Policies",
  "View Policies": "View Policies",
  "Policy History": "Policy History",
  "Finance Amount": "Finance Amount",
  "Last Installment Date": "Last Installment Date",
  "Net Proceed": "Net Proceed",
  "Total Amount": "Total Amount",
  "Total Fee": "Total Fee",
  Vat: "Vat",
  "Admin Fee": "Admin Fee",
  "Amortization Rate": "Amortization Rate",
  "Calculated Admin Fee": "Calculated Admin Fee",
  "Calculated Vat": "Calculated Vat",
  "Apr Rate": "Apr Rate",
  "Emi monthly Installement": "Emi monthly Installement",
  "First Installment Date": "First Installment Date",
  "Interest Amount": "Interest Amount",
  Month: "Month",
  "Monthly Installment": "Monthly Installment",
  "Interest Amount Table": "Interest Amount Table",
  "Last Installment Date": "Last Installment Date",
  "Outstanding Principal": "Outstanding Principal",
  "Principal Amount Table": "Principal Amount Table",
  "Due Installment Date": "Due Installment Date",
  "Re-Payment Schedule": "Re-Payment Schedule",
  "Download as PDF": "Download as PDF",
  Selaa: "Selaa",
  Transaction: "Transaction",
  Commodity: "Commodity",
  Wallet: "Wallet",
  "Customers EMI": "Customers EMI",
  "Add SMS": "Add SMS",
  DBR: "DBR",
  "Gross Salary": "Gross Salary",
  "Include Mtg": "Include Mtg",
  "Simah Liabilities": "Simah Liabilities",
  "Total Dependents": "Total Dependents",
  "Final Eligible EMI": "Final Eligible EMI",
  "Domestic Worker": "Domestic Worker",
  "Disposable Income": "Disposable Income",
  Children: "Children",
  "Transaction Id": "Transaction Id",
  "Transaction Type": "Transaction Type",
  "Transaction Currency": "Transaction Currency",
  "Transaction Amount": "Transaction Amount",
  "Status Check Retry Count": "Status Check Retry Count",
  "Sender Account": "Sender Account",
  "Receiver Account": "Receiver Account",
  "Emi Detail": "Emi Detail",
  "Loan History": "Loan History",
  "Transaction History": "Transaction History",
  "Awareness Messages": "Awareness Messages",
  "Check Eligibility": "Check Eligibility",
  "View Simah Report": "View Simah Report",
  "Simah Report": "Simah Report",
  Amount: "Amount",
  "Lended Id": "Lended Id",
  "Wallet Name": "Wallet Name",
  Certificates: "Certificates",
  "Lender Internal Id": "Lender Internal Id",
  "lenders Customer Id": "lenders Customer Id",
  "All Ownership Id": "All Ownership Id",
  "Owner Id": "Owner Id",
  File: "File",
  Transfer: "Transfer",
  Radeem: "Radeem",
  "Owner Ship File": "OwnerShip File",
  Eligibility: "Eligibility",
  "Selaa History": "Selaa History",
  owner: "owner",
  "ownership Id": "ownership Id",
  "redeem Allowed": "redeem Allowed",
  "Selaa Transaction": "Selaa Transaction",
  "Lender Id": "Lender Id",
  Balance: "Balance",
  Locked: "Locked",
  "Messsage Type": "Messsage Type",
  "Language Code": "Language Code",
  "Phone Number": "Phone Number",
  Phone: "Phone",
  "Add New Sms or Otp": "Add New Sms or Otp",
  "Sms & Otp": "Sms & Otp",
  description: "description",
  "Search With Product Group": "Search With Product Group",
  "Search With Code": "Search With Code",
  "Locked Amount": "Locked Amount",
  "User Answers": "User Answers",
  "Field / Options": "Field / Options",
  City: "City",
  Country: "Country",
  "Mac Address": "Mac Address",
  "Reset Counter": "Reset Counter",
  "Add More Months": "Add More Months",
  "Heading Arabic": "Heading Arabic",
  "Heading English": "Heading English",
  "Question English": "Question English",
  "Question Arabic": "Question Arabic",
  "Set Name In Arabic": "Set Name In Arabic",
  "Set Name In English": "Set Name In English",
  "Message Type": "Message Type",
  "Add New Role": "Add New Role",
  "Select Role": "Select Role",
  "Add More Months": "Add More Months",
  "": "",
  "Repayment Account": "Repayment Account",
  "Disbursement Account": "Disbursement Account",
  "Seulah Bank Account Details": "Seulah Bank Account Details",
  "Account Title": "Account Title",
  "Account Number": "Account Number",
  IBAN: "IBAN",
  Save: "Save",
  "Delete Bank": "Delete Bank",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
  "": "",
};
